<template>
  <div class="home_div">
    <div class="backend"></div>
    <div class="video">
      <video class="myVideo" loop muted autoplay>
        <source src="~assets/video/1.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="mainlogo"></div>
    <div class="wallet"></div>
    <div
      class="connect"
      :class="{ connected: isConnected }"
      ref="connectButton"
      id="connectButton"
      @click="onClickConnect()"
    >
      {{ accountAddress }}
    </div>
    <div class="body">
      <div class="header">
        <div class="label">
          <span @click="scrollTo('buy')">BUY</span>
          <span @click="scrollTo('collection')">Collection</span>
          <span @click="scrollTo('world')">WORLD</span>
          <!-- <span @click="scrollTo('game')">GAME</span> -->
        </div>
      </div>
      <div class="box1">
        <div class="road"></div>
        <div class="logo1"></div>
        <div class="official"></div>
        <div class="hongxing"></div>
        <div class="lanxing"></div>
      </div>
      <div class="body-box">
        <div class="back1"></div>
        <div class="word"></div>
        <div class="box" ref="buy" id="buy">
          <div class="left">
            <div class="photos"></div>
            <div class="photo-net"></div>
          </div>
          <div class="right">
            <div class="item">
              <span class="item-left">ETH BALANCE</span>
              <span class="item-right">{{ balance }} ETH</span>
            </div>
            <div class="item higher">
              <span class="item-left">AMOUNT</span>
              <div class="normal-box">
                <div
                  class="normal item-left subtract"
                  :class="{ sub_disable: subDisable }"
                  @click="substract()"
                ></div>
                <span class="normal item-right">{{ num }}</span>
                <div
                  class="normal item-left add"
                  :class="{ add_disable: addDisable }"
                  @click="add()"
                ></div>
              </div>
              <div class="max-box" @click="max()">MAX</div>
            </div>
            <div class="item">
              <span class="item-left">TOTAL BALANCE</span>
              <span class="item-right">{{ 0.01 * num }} EHT</span>
            </div>
            <div class="buy" @click="buy()">
              <img class="buy-img" src="~assets/images/buynow.png" alt="" />
              <!-- <img class="buy-img sold-out" src="~assets/images/soldout.png" alt=""> -->
            </div>
            <div class="progress-title">PROGRESS</div>
            <div class="progress" style="height: 18px">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{ width: totalsupply }"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <div class="collection" id="collection">
          <div class="collection-title"></div>
          <div class="collection-back"></div>
          <div class="collection-box">
            <div class="collection-item">
              <img class="collection-img" src="~assets/images/item2.png" alt="" />
              <img class="collection-kuang" src="~assets/images/item1.png" alt="" />
            </div>
            <div class="collection-item">
              <img class="collection-img" src="~assets/images/item2.png" alt="" />
              <img class="collection-kuang" src="~assets/images/item1.png" alt="" />
            </div>
            <div class="collection-item">
              <img class="collection-img" src="~assets/images/item2.png" alt="" />
              <img class="collection-kuang" src="~assets/images/item1.png" alt="" />
            </div>
            <div class="collection-item">
              <img class="collection-img" src="~assets/images/item2.png" alt="" />
              <img class="collection-kuang" src="~assets/images/item1.png" alt="" />
            </div>
            <div class="collection-item">
              <img class="collection-img" src="~assets/images/item2.png" alt="" />
              <img class="collection-kuang" src="~assets/images/item1.png" alt="" />
            </div>
            <div class="collection-item">
              <img class="collection-img" src="~assets/images/item2.png" alt="" />
              <img class="collection-kuang" src="~assets/images/item1.png" alt="" />
            </div>
          </div>
          <div class="more">SEE MORE</div>
        </div>
        <div class="back2">
          <div class="bian top"></div>
          <video class="video2" loop muted autoplay>
            <source src="~assets/video/2.mp4" type="video/mp4" />
          </video>
          <div class="bian di"></div>
        </div>
      </div>
      <div class="world-box" id="world">
        <div class="world-title"></div>
        <div class="words">
          Ovat continent is a magical and dynamic world in the digital universe. Many
          creators have a special liking for this world, and they have poured a lot of
          divine power to create many (small world) legends with different styles and
          different styles, making the Ovat continent more prosperous.<br />
          Prosperity and prosperity attracted attention from the darkness, and Avris, the
          lord of greed and destruction, who eroded the entire digital universe, extended
          his claws to this happy world. The creators were completely unable to resist the
          power of Afris’ desire to dominate. The continent was gradually eroded by greed
          and darkness. The creators could only lay down barriers to protect the small
          world they created from being eroded, and there was no more power to go. Dispel
          the darkness above the continent.
        </div>
      </div>
      <!-- <div class="team" id="game">
          <div class="team-title"></div>
          <div class="team-box">
            <div class="team-item one"></div>
            <div class="team-item two"></div>
            <div class="team-item three"></div>
            <div class="team-item four"></div>
          </div>
        </div> -->
      <div class="bottom">
        <div class="bottom-box">
          <div class="bottom-item one"></div>
          <div class="bottom-item two"></div>
          <div class="bottom-item three"></div>
        </div>
        <div class="bottom-content">Smart Contract</div>
      </div>
    </div>
    <div
      v-if="btnFlag"
      class="back-to-top animate__animated animate__bounceInUp"
      @click="backTop"
    ></div>
  </div>
</template>

<script>
import MetaMaskOnboarding from "@metamask/onboarding";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";

export default {
  name: "Home",
  data() {
    return {
      num: 1,
      maxNum: 10,
      btnFlag: false,
      subDisable: true,
      addDisable: false,
      provider: {},
      accounts: "",
      balance: 0,
      MinitContract: "",
      accountAddress: "",
      accountButtonsInitialized: false,
      price: 0,
      gasPrice: 0,
      maxLimit: "",
      isConnected: false,
      chainId: '',
      totalsupply: '',
    };
  },
  watch: {
    num: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal <= 1) {
          this.subDisable = true;
        } else if (newVal >= this.maxNum) {
          this.addDisable = true;
        } else {
          this.subDisable = false;
          this.addDisable = false;
        }
      },
    },
  },
  mounted() {
    const ip = localStorage.getItem('Ip')
    const cityname = localStorage.getItem('cityname')
    console.info('ip:'+ip)
    console.info('city:'+cityname)
    window.addEventListener("scroll", this.scrollToTop);
    setInterval(() => {
      this.getAccount()
    }, 1000)
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  components: {},
  created() {
    this.initialize();
  },
  methods: {
    substract() {
      if (this.num > 1) {
        this.num--;
      }
    },
    add() {
      if (this.num < this.maxNum) {
        this.num++
      }
    },
    max() {
      this.subDisable = false;
      this.num = this.maxNum;
    },
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop () {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 0) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      const targetOffsetTop = $(`#${index}`).offset().top - 120;
      // 获取当前 offsetTop
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      console.log(targetOffsetTop);
      console.log(scrollTop);
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    },

    isMetaMaskInstalled() {
      const { ethereum } = window;
      return Boolean(ethereum && ethereum.isMetaMask);
    },
    isMetaMaskConnected() {
      // console.log(localStorage.getItem('islogined'), 'isMetaMaskConnected');
      this.accounts = localStorage.getItem("islogined");
      // console.log(this.accounts, 'this.account');
      return Boolean(
        this.accounts !== "" &&
          this.accounts !== null &&
          typeof this.accounts !== "undefined"
      );
    },
    getBalance(account) {
      if(account == ''){
        this.balance=0;
      }
      this.provider.getBalance(account).then((balance) => {
        // 余额是 BigNumber (in wei); 格式化为 ether 字符串
        let etherString = ethers.utils.formatEther(balance);
        this.balance = etherString;
        console.log("Balance: " + etherString);
        return etherString;
      });
    },
    onClickInstall() {
      var choose = confirm("Cannot connect to MetaMask,are you sure it has been installed?");
      if(choose == true){
        window.location.href="https://metamask.io/download.html";
        return false;
      }
    },
    async onClickConnect() {
      if (!this.isConnected) {
        console.log("钱包正在登陆中");
        // 检查狐狸钱包是否安装
        if (!this.isMetaMaskInstalled()) {
          console.log(
            this.isMetaMaskInstalled(),
            this.isMetaMaskInstalled() ? "已安装" : "未安装"
          );
          console.log("Cannot connect to MetaMask,are you sure it has been installed?");
          // alert("Cannot connect to MetaMask,are you sure it has been installed?");
          this.onClickInstall();
        } else {
          try {
            const newAccounts = await ethereum.request({
              method: "eth_requestAccounts",
            });
            console.log(newAccounts[0], "onClickConnect");
            this.handleNewAccounts(newAccounts[0]);
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
    async getAccount() {
      try {
        const newAccounts = await ethereum.request({
          method: "eth_accounts",
        });
        if (typeof newAccounts[0] === "undefined") {
          this.logout();
        }
      } catch (error) {
        console.error(error);
      }
    },
    initializeAccountButtons() {
      console.log(this.accountButtonsInitialized, "initializeAccountButtons");
      if (this.accountButtonsInitialized) {
        return;
      }
      this.accountButtonsInitialized = true;
      console.log(this.accountButtonsInitialized, "initializeAccountButtons");
      /**
       * Contract Interactions
       */
      // todo
    },
    handleNewAccounts(newAccounts) {
      console.log(newAccounts, "handleNewAccounts");
      // this.accounts = newAccounts;
      localStorage.removeItem("islogined");
      localStorage.setItem("islogined", newAccounts);
      if (this.isMetaMaskConnected()) {
        this.initializeAccountButtons();
      }
      this.updateButtons();
    },
    handleNewChain(chainId) {
      this.chainId=chainId;
      console.log(chainId);
    },
    handleNewNetwork(networkId) {
      console.log(networkId);
    },
    clearTextDisplays() {
      // todo 初始化钱包未登陆状态下的按钮及合约的值
      console.log("todo 初始化钱包未登陆状态下的按钮及合约的值", "处理购买表单");
    },

    updateButtons() {
      if (!this.isMetaMaskInstalled() || !this.isMetaMaskConnected()) {
        //处理购买表单
        this.clearTextDisplays();
      }
      //处理登陆按钮
      if (!this.isMetaMaskInstalled()) {
        console.log("安装钱包的按钮");
        // this.onboardButton.innerText = "Click here to install MetaMask!";
        // this.onboardButton.onClick = onClickInstall();
      } else if (this.isMetaMaskConnected()) {
        console.log("显示登陆钱包地址" + this.accounts);
        // 处理购买表单
        console.log(
          "nft合约，最大，最小，剩余数量，总数量，单价，购买按钮的状态",
          "处理购买表单"
        );
        this.isConnected = true
        this.accountAddress = this.accounts;
        this.getBalance(this.accounts);
      } else {
        this.isConnected = false
        this.balance = 0
        console.log("显示登陆的按钮");
        this.accountAddress = "";
        this.balance = 0;
      }
    },
    async getNetworkAndChainId() {
      try {
        const chainId = await ethereum.request({
          method: "eth_chainId",
        });
        this.handleNewChain(chainId);

        const networkId = await ethereum.request({
          method: "net_version",
        });
        this.handleNewNetwork(networkId);
      } catch (err) {
        console.error(err);
      }
    },
    async getTotalsupply(){
      const config = require("../contracts/config.json");
      const ERC721Token = require("../contracts/ERC721Token.json");
      const ERC721_contract_address = config.ERC721;
      const ERC721_abi = ERC721Token.abi;
      const ERC721Contract = new ethers.Contract(ERC721_contract_address, ERC721_abi, this.provider);
      console.log(ERC721Contract, 'ERC721Contract');

      let totalsupply = await ERC721Contract.totalSupply();
      console.log(ethers.BigNumber.from(totalsupply).toNumber(), 'totalsupply');      
      this.totalsupply = Number((ethers.BigNumber.from(totalsupply).toNumber()/ethers.BigNumber.from('10000').toNumber())*100)+ "%";
      console.log(this.totalsupply, 'totalsupply');
    },

    async buy() {

      if(!this.isMetaMaskInstalled()){
        this.onClickInstall();
        return false;
      }
      if (!this.isMetaMaskConnected()) {
        this.onClickConnect();
        return false
      }

      const config = require("../contracts/config.json");
      const ERC721Factory = require("../contracts/ERC721Factory.json");
      let signer = this.provider.getSigner();

      console.log(this.provider, "network");
      const contract_address = config.FACTORY;

      const abi = ERC721Factory.abi;
      const readOnlyContract = new ethers.Contract(contract_address, abi, this.provider);

      console.log(readOnlyContract);
      //合约地址
      let address = await readOnlyContract.address;
      console.log(address.toString(), "address");

      //单价
      let price = await readOnlyContract.price();
      this.price = ethers.utils.formatEther(price);

      console.log(price, "price");
      console.log(ethers.utils.formatEther(price), "price");
      console.log(ethers.BigNumber.from(price).toString(), "price");
      //购买数量限制
      let limit = await readOnlyContract.limit();
      this.maxLimit = ethers.BigNumber.from(limit).toString();
      console.log(limit, "maxLimit");
      console.log(ethers.BigNumber.from(limit).toString(), "maxLimit");

      // console.log(await readOnlyContract);

      // await signer.sendTransaction({
      //   to: "0x9A9De2F94Df324E31Fabe45a6900c5738114E3f8",
      //   value: 10000000000000,
      //   gasPrice: 1000000000,
      //   gasLimit: 85000,
      //   value:  1
      // });

      const contract = new ethers.Contract(contract_address, abi, signer);
      console.log(contract, "contract");
      console.log(contract_address, "合约地址");
      console.log(this.accounts, "买方 singer");

      // 合约内操作
      // const Unit18 = BigNumber.from(10).pow(18);

      // let test_amount = ethers.BigNumber.from(10).pow(18).mul(2).toString();
      // console.log(test_amount, "test_amount1 ");
      // 将BigNumber转换为十进制数 ethers.BigNumber.from(test_amount).toString()，61 to 10
      // console.log(ethers.BigNumber.from(test_amount).toString(), "test_amount2 ");
      // 10 to 16
      // console.log(ethers.utils.parseUnits(test_amount, 18), "test_amount3");

      console.log(this.chainId, 'this.chainId');
      console.log(this.num, 'this.num');
      let total_balance= this.price*this.num;
      let options = {
        // gasPrice: 2000000000,
        gasPrice: this.gasPrice,
        gasLimit: 5000000,
        value: ethers.utils.parseUnits(total_balance.toString()),


        // value: ethers.BigNumber.from(10).pow(18).mul(this.num).toString(),
        // chainId: 4,
        // nonce: nonce,
      };

      const BigNumber = require('ethers').utils.BigNumber;
      console.log(options.gasPrice, options.gasLimit + "---------test");
      const tx = await contract.functions.mint(1, options);
      console.log(tx, "tx");
      const t = await tx.wait();
      console.log(t);

      const tx_hash = tx.hash;
      const link = `https://${config.NETWORK}.etherscan.io/tx/` + tx_hash;
      console.log(link);

      // https://testnets.opensea.io/get-listed/step-two
      // https://testnets.opensea.io/collection/erc721-nheha8w6vz
    },
    logout() {
      localStorage.setItem("islogined", "");
      localStorage.removeItem("islogined");
      this.accounts = "";
      this.updateButtons();
    },
    getGasPrice() {
      this.provider.getGasPrice().then((gasPrice) => {
        // gasPrice is a BigNumber; convert it to a decimal string
        let gasPriceString = gasPrice.toString();
        console.log("Current gas price: " + gasPriceString);
        this.gasPrice = gasPriceString;

        return gasPriceString;
      });
    },

    initialize() {
      if (this.isMetaMaskInstalled()) {
        this.provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = this.provider.getSigner();

        // console.log(this.provider, "provider");
        // console.log(signer, "signer");

        // const currentUrl = new URL(window.location.href);
        // const forwarderOrigin =
        //   currentUrl.hostname === "localhost" ? "http://localhost:8080" : undefined;

        // let onboarding;
        // try {
        //   onboarding = new MetaMaskOnboarding({ forwarderOrigin });
        // } catch (error) {
        //   console.error(error);
        // }

        console.log("初始化开始");
        this.getNetworkAndChainId();

        this.getTotalsupply();
        // 同步metamask登陆状态，发现未登陆，处理退出。
        this.getAccount();
        this.getGasPrice();
        // 检查狐狸钱包是否登陆
        console.log(
          this.isMetaMaskConnected(),
          this.isMetaMaskConnected() ? "已登陆" : "未登陆"
        );

        // 判断是否操作过登陆
        if (this.isMetaMaskConnected()) {
          console.log("自動登陸");
          // this.getAccount();
        }
        // 退出，判断钱包的登陆账号是否同网站的一致
        // this.logout();
        // if(this.isMetaMaskConnected())
        // {
        //   this.logout();
        // }

        // 更新按钮及初始化合约参数

        this.updateButtons();
        console.log("初始化结束");
      }
    },
  },
};
</script>
<style lang="less" scoped>
a {
  color: #572d25;
  text-decoration: none;
  outline: none;
}
.home_div {
  background: #fff;
  position: relative;
  .backend {
    width: 100%;
    height: 100%;
    background: url("~assets/images/backend.png") no-repeat center;
    background-size: cover;
    position: fixed;
  }
  .video {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 13.5rem;
    background: #fff;
  }
  .myVideo {
    width: 100%;
    height: 13.5rem;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
  }
  .mainlogo {
    height: 1.1375rem;
    width: 2.6rem;
    background: url("~assets/images/logo2.png") no-repeat center;
    background-size: cover;
    left: 3.525rem;
    position: absolute;
    top: 0.125rem;
    z-index: 2;
  }
  .wallet {
    width: 3.625rem;
    height: 3.6rem;
    background: url("~assets/images/wallet1.png") no-repeat center;
    background-size: cover;
    top: -1.5rem;
    position: absolute;
    right: 3rem;
    z-index: 2;
  }
  .connect {
    width: 1.45rem;
    height: 0.3rem;
    background: url("~assets/images/connect.png") no-repeat center;
    background-size: cover;
    position: absolute;
    top: 0.95rem;
    right: 4.0875rem;
    z-index: 2;
    cursor: pointer;
    font-size: 0.225rem;
    color: #f586a8;
    overflow: hidden;
    font-weight: bold;
    text-overflow: ellipsis;
  }
  .connected {
    cursor: default;
    background: none !important;
    width: 2.05rem;
    right: 3.5875rem;
  }
  .body {
    .header {
      width: 100%;
      height: 1.375rem;
      background: #fff;
      opacity: 0.85;
      backdrop-filter: blur(30px) brightness(20%) opacity(85%);
      display: flex;
      align-content: center;
      position: relative;
      z-index: 1;
      .label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 460px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Sitka Text";
        span {
          color: #572d25;
          cursor: pointer;
          margin-right: 72px;
          font-size: 20px;
          font-weight: bold;
        }
        span:hover {
          color: #f586a8;
        }
      }
    }
    .box1 {
      width: 100%;
      height: 12.125rem;
      position: relative;
      .road {
        width: 100%;
        height: 4.35rem;
        background: url("~assets/images/road.png") no-repeat center;
        background-size: cover;
        position: absolute;
        bottom: 0px;
      }
    }
    .logo1 {
      width: 11.7125rem;
      height: 7.175rem;
      background: url("~assets/images/logo3.png") no-repeat center;
      background-size: cover;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 6.1875rem;
    }
    .official {
      width: 2.8125rem;
      height: 2.675rem;
      background: url("~assets/images/official.png") no-repeat center;
      background-size: cover;
      position: absolute;
      left: 2.7875rem;
      top: 9.2125rem;
    }
    .hongxing {
      width: 2.95rem;
      height: 2.7625rem;
      background: url("~assets/images/hongxing.png") no-repeat center;
      background-size: cover;
      position: absolute;
      right: 3.8875rem;
      top: 7.6rem;
    }
    .lanxing {
      width: 2.95rem;
      height: 2.7625rem;
      background: url("~assets/images/lanxing.png") no-repeat center;
      background-size: cover;
      position: absolute;
      right: 2.5375rem;
      top: 9.125rem;
    }
    .body-box {
      width: 100%;
      background: #fff;
      position: relative;
      overflow: hidden;
      padding-top: 500px;
    }
    .back1 {
      width: 1094px;
      height: 405px;
      background: url("~assets/images/back1.png") no-repeat center;
      background-size: cover;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 82.5px;
    }
    .word {
      width: 952px;
      height: 293px;
      background: url("~assets/images/word.png") no-repeat center;
      background-size: cover;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 139px;
      z-index: 2;
    }
    .box {
      width: 1160px;
      height: 516px;
      margin: 0 auto;
      margin-top: 120px;
      display: flex;
      justify-content: space-between;
      .left {
        width: 421px;
        height: 100%;
        @keyframes frame {
          0% {
            background-image: url("~assets/images/avatar/1.png");
          }
          8.3% {
            background-image: url("~assets/images/avatar/2.png");
          }
          16.6% {
            background-image: url("~assets/images/avatar/3.png");
          }
          24.9% {
            background-image: url("~assets/images/avatar/4.png");
          }
          33.2% {
            background-image: url("~assets/images/avatar/5.png");
          }
          41.5% {
            background-image: url("~assets/images/avatar/6.png");
          }
          49.8% {
            background-image: url("~assets/images/avatar/7.png");
          }
          58.1% {
            background-image: url("~assets/images/avatar/8.png");
          }
          64.4% {
            background-image: url("~assets/images/avatar/9.png");
          }
          72.7% {
            background-image: url("~assets/images/avatar/10.png");
          }
          81% {
            background-image: url("~assets/images/avatar/11.png");
          }
          89%,
          100% {
            background-image: url("~assets/images/avatar/12.png");
          }
        }
        .photos {
          width: 421px;
          height: 421px;
          // background: #F586A8;
          border-radius: 12px;
          background-image: url("~assets/images/avatar/1.png");
          background-repeat: no-repeat;
          background-size: cover;
          animation: frame 3.6s steps(1, end) both infinite;
        }
        .photo-net {
          width: 318px;
          height: 64px;
          margin-top: 37px;
          margin-left: 25px;
          background: url("~assets/images/10000.png") no-repeat center;
          background-size: contain;
        }
      }
      .right {
        width: 679px;
        height: 100%;
        .item {
          width: 100%;
          height: 75px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 24px;
          font-weight: bold;
          border-bottom: 2px solid #f586a8;
          font-family: "Britannic";
          .item-left {
            color: #f586a8;
          }
          .item-right {
            color: #56291f;
          }
          .max-box {
            cursor: pointer;
            width: 88px;
            height: 54px;
            border-radius: 30px;
            background: #56291f;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
          }
          .normal-box {
            display: flex;
            align-items: center;
            justify-self: normal;
            margin-left: 200px;
            font-size: 36px;
            font-weight: bold;
            .normal {
              margin-right: 24px;
            }
            .subtract {
              width: 24px;
              height: 6px;
              background: url("~assets/images/sub.png") no-repeat center;
              background-size: cover;
            }
            .sub_disable {
              cursor: default !important;
              background: url("~assets/images/sub_disable.png") no-repeat center !important;
              background-size: cover;
            }
            .add {
              width: 24px;
              height: 24px;
              background: url("~assets/images/add.png") no-repeat center;
              background-size: cover;
            }
            .add_disable {
              cursor: default !important;
              background: url("~assets/images/add_disable.png") no-repeat center !important;
              background-size: cover;
            }
            .subtract,
            .add {
              cursor: pointer;
            }
          }
        }
        .higher {
          /*justify-content: flex-start;*/
          height: 102px;
        }
        .buy {
          cursor: pointer;
          width: 100%;
          height: 90px;
          background: #f586a8;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          margin-top: 48px;
          font-size: 36px;
          border-radius: 12px;
          .buy-img {
            width: 194px;
            height: 36px;
          }
        }
        .progress-title {
          font-weight: bold;
          margin-top: 51px;
          color: #f586a8;
          font-size: 24px;
        }
        .progress {
          height: 18px;
          margin-top: 24px;
          border-radius: 12px;
          .progress-bar {
            border-radius: 12px;
            background: #f586a8;
          }
        }
      }
    }
    .collection {
      width: 1160px;
      height: 1149px;
      margin: 0 auto;
      margin-top: 140px;
      position: relative;
      .collection-title {
        width: 326px;
        height: 75px;
        margin: 0 auto;
        background: url("~assets/images/collection_title.png") no-repeat center;
        background-size: cover;
      }
      .collection-back {
        width: 1474px;
        height: 695px;
        position: absolute;
        top: 160px;
        left: 50%;
        transform: translateX(-50%);
        background: url("~assets/images/collection_back.png") no-repeat center;
        background-size: cover;
      }
      .collection-box {
        margin-top: 85px;
        width: 1160px;
        display: flex;
        flex-wrap: wrap;
        .collection-item {
          width: 320px;
          height: 336px;
          margin-right: 100px;
          margin-bottom: 100px;
          display: flex;
          justify-content: center;
          position: relative;
          .collection-kuang {
            width: 100%;
            height: 100%;
            position: absolute;
          }
          .collection-img {
            position: relative;
            margin-top: 5px;
            width: 309px;
            height: 309px;
          }
        }
        .collection-item:nth-child(3n) {
          margin-right: 0;
        }
      }
      .more {
        cursor: pointer;
        width: 302px;
        height: 92px;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #56291f;
        color: white;
        border-radius: 50px;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .back2 {
      width: 100%;
      height: 477px;
      margin-top: 120px;
      .bian {
        width: 100%;
        height: 24px;
      }
      .top {
        background: url("~assets/images/bian.png") no-repeat center;
        background-size: cover;
      }
      .di {
        margin-top: -0.2875rem;
        background: url("~assets/images/di.png") no-repeat center;
        background-size: cover;
      }
      .video2 {
        width: 1920px;
        height: 429px;
        background-position: center center;
        background-size: contain;
        object-fit: cover;
      }
    }
    .world-box {
      position: relative;
      z-index: 1;
      margin-top: 152px;
      .world-title {
        width: 200px;
        height: 75px;
        margin: 0 auto;
        background: url("~assets/images/world_title.png") no-repeat center;
        background-size: cover;
      }
      .words {
        text-align: center;
        font-family: "Britannic";
        margin: 0 auto;
        line-height: 98px;
        color: #6a362d;
        width: 1132px;
        margin-top: 155px;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .team {
      position: relative;
      z-index: 1;
      margin-top: 160px;
      .team-title {
        width: 276px;
        height: 75px;
        margin: 0 auto;
        background: url("~assets/images/team_title.png") no-repeat center;
        background-size: cover;
      }
      .team-box {
        margin: 0 auto;
        margin-top: 147px;
        width: 1261px;
        display: flex;
        justify-content: space-between;
        .team-item {
          cursor: pointer;
          width: 232px;
          height: 333px;
        }
        .one {
          background: url("~assets/images/team1.png") no-repeat center;
          background-size: cover;
        }
        .two {
          background: url("~assets/images/team2.png") no-repeat center;
          background-size: cover;
        }
        .three {
          background: url("~assets/images/team3.png") no-repeat center;
          background-size: cover;
        }
        .four {
          background: url("~assets/images/team4.png") no-repeat center;
          background-size: cover;
        }
      }
    }
    .bottom {
      position: relative;
      z-index: 1;
      margin-top: 221px;
      .bottom-box {
        width: 228px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .bottom-item {
          cursor: pointer;
          width: 60px;
          height: 60px;
        }
        .one {
          background: url("~assets/images/bottom1.png") no-repeat center;
          background-size: cover;
        }
        .two {
          background: url("~assets/images/bottom2.png") no-repeat center;
          background-size: cover;
        }
        .three {
          background: url("~assets/images/bottom3.png") no-repeat center;
          background-size: cover;
        }
      }
      .bottom-content {
        cursor: pointer;
        width: 162px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 88px;
        color: #6a362d;
        font-size: 24px;
        font-family: "Britannic";
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
  .back-to-top {
    cursor: pointer;
    width: 1.475rem;
    height: 1.675rem;
    background: url("~assets/images/top.png") no-repeat center;
    background-size: cover;
    position: fixed;
    right: 2.7875rem;
    bottom: 1.25rem;
    z-index: 99;
  }
}
</style>
