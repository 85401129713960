import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "views/Home.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta:{
      title: 'OtaWorld'
    }
  }
];

const scrollBehavior = function (to, from, savedPosition) {
  if (to.hash) {
    return {
      // 通过 to.hash 的值來找到对应的元素
      selector: to.hash
    }
  }
}
const router = new VueRouter({
  mode: 'history', 
  routes,
  scrollBehavior
})

export default router
