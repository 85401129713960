<template>
  <router-view />
</template>
<script>
export default {
  name: 'App',
  // created() {
  //   if (this._isMobile()) {
  //     import('assets/js/phone_flexable.js')
  //   } else {
  //     import('assets/js/flexable.js')
  //   }
  // },
  // methods:{
	// 	_isMobile(){
	// 		let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)      
	//     return flag;
	// 	}
	// }
}
</script>
<style lang="less">
body{
  background-color: black !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
