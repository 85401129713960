import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import '@/assets/css/bootstrap.min.css'
import axios from 'axios'
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import ('assets/js/flexable.js')

Vue.use(animated)








Vue.use(Vuex)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
Vue.prototype.$http = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
 
